import { ChunkDetail, DbChunkDetail } from './chunk-detail';


export interface DbGridAnswer {
  text: string;
  doc_uid: string;
  chunk_hash: string;
  chunk_details: { [key: string]: DbChunkDetail };
  previous_chunk_detail?: DbChunkDetail;
  following_chunk_detail?: DbChunkDetail;
}

export interface TextItem {
  text?: string;
  ref?: string;
}
export class GridAnswer {
  questionId: number;
  drawChunk: boolean;
  text: string;
  docUid: string;
  chunkHash: string;
  chunkDetails: { [key: string]: ChunkDetail };
  previousChunkDetail?: ChunkDetail;
  followingChunkDetail?: ChunkDetail;
  textWithRefs: TextItem[] = [];

  constructor(question_id: number, dbGridAnswer: DbGridAnswer) {
    this.questionId = question_id;
    this.drawChunk = true
    this.text = dbGridAnswer.text;
    this.docUid = dbGridAnswer.doc_uid;
    this.chunkHash = dbGridAnswer.chunk_hash;
    this.chunkDetails= Object.keys(dbGridAnswer.chunk_details).reduce((acc, key) =>
        ({ ...acc, [key]: new ChunkDetail(dbGridAnswer.chunk_details[key]) }), {});
    this.previousChunkDetail = dbGridAnswer.previous_chunk_detail ? new ChunkDetail(dbGridAnswer.previous_chunk_detail) : undefined;
    this.followingChunkDetail = dbGridAnswer.following_chunk_detail ? new ChunkDetail(dbGridAnswer.following_chunk_detail) : undefined;
  }
}
